<template>
  <div class="register pb-5">
    <div class="container-lg">
      <div class="row mb-3">
        <div class="col-sm-11 mx-auto">
          <img src="@/assets/img/register/reg-banner.jpg" class="img-fluid" alt="registration banner" />
        </div>
      </div>



      <h2 class="text-center"> Registration closed</h2>

    </div>
    <!-- success modal -->
    <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center p-5">
            <div class="row justify-content-center mb-4">
              <div class="col-2">
                <img src="@/assets/img/register/succesicon.png" class="img-fluid" alt="success icon" />
              </div>
            </div>
            <h1 class="mb-3">Registration Complete</h1>
            <p class="text-cener">
              Thank you for registering for PDP Week 2024. An email confirmation
              will be sent to you shortly. Please contact us at
              <a href="mailto:pdp.enquiries@pico.com">pdp.enquiries@pico.com</a>
              if you do not receive the email within the next hour.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- failed modal -->
    <div class="modal fade" id="failedModal" tabindex="-1" aria-labelledby="failedModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center p-5">
            <div class="row justify-content-center mb-4">
              <div class="col-2">
                <img src="@/assets/img/register/error.png" class="img-fluid" alt="success icon" />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-8 mx-auto text-left">
                <p class="h5 mb-2">Please complete the required fields.</p>
                <span class="text-danger">{{
                  `&#x2022; ${selectanyone}`
                }}</span>
                <span v-if="!terms_checkbox && showError" class="text-danger">&#x2022; Please accept the Terms &
                  Conditions.</span>
                <div class="error-wrapper" v-for="(err, index) in Object.keys(validation_message)">
                  <span class="text-danger" v-for="message of validation_message[err]" :key="`${err}-${message}`">{{
                    `&#x2022; ${message}` }}<br /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- WAITLIST -->
    <div class="modal fade" id="waitListModal" tabindex="-1" aria-labelledby="waitListModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center p-5">
            <div class="row justify-content-center mb-4">
              <div class="col-2">
                <img src="@/assets/img/register/succesicon.png" class="img-fluid" alt="success icon" />
              </div>
            </div>
            <h1 class="mb-3">Waitlist Request Submitted</h1>
            <p class="text-cener">
              Thank you for your interest in PDP Week 2024. An acknowledgement
              email will be sent to you shortly. Please contact us at
              <a href="mailto:pdp.enquiries@pico.com">pdp.enquiries@pico.com</a>
              if you do not receive the email within the next hour.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Csrf from "@/assets/js/Csrf";
import Api from "@/assets/js/Api";
import $ from "jquery";
export default {
  name: "register",
  mounted() {
    if (this.$route.query.utm_source) {
      // console.log("this.$route.query.utm_source", this.$route.query.utm_source);
      this.participant.utm_source = this.$route.query.utm_source;
    }

    Api.get("/get-sessions-limit").then((res) => {
      console.log("data", res);
      this.loading_limit = 0;

      this.limits = res.data.limit;
    });
  },
  data() {
    return {
      loading_limit: 1,
      disable: "",
      loading: false,
      terms_checkbox: false,
      showError: false,
      participant: {
        utm_source: "",
        salutation: "",
        salutation_other: "",
        first_name: "",
        last_name: "",
        email: "",
        email_confirmation: "",
        phone: "",
        job_title: "",
        job_level: "",
        job_level_other: "",
        organization: "",
        organization_nature: "",
        organization_nature_other: "",
        organization_size: "",
        country: "",
        dpo: "",
        pdp_week: false,
        pets_101: false,
        navigating_cross_border_data_flows: false,
        decoding_generative_ai: false,
        workshop_on_ai_governance: false,
      },
      validation_message: {
        salutation: [],
        salutation_other: [],
        first_name: [],
        last_name: [],
        email: [],
        email_confirmation: [],
        phone: [],
        job_title: [],
        job_level: [],
        job_level_other: [],
        organization: [],
        organization_nature: [],
        organization_nature_other: [],
        organization_size: [],
        country: [],
        dpo: [],

        pdp_week: [],
        pets_101: [],
        navigating_cross_border_data_flows: [],
        decoding_generative_ai: [],
        workshop_on_ai_governance: [],
      },

      capacity_message: {
        pdp_limit: "",
        th_data_soverignty_session: "",
        virtual_roadahead_session: "",
        fr_dpo_session: "",
        fr_navigating_breach_session: "",
        wed_data_protection_obligation_session: "",
        wed_navigating_data_breach_session: "",
      },
      selectanyone: "",
      selected_session_count: 0,
      limits: {},
      isEmailValid: false,
    };
  },
  watch: {
    participant: {
      handler(newValue, oldValue) {
        this.sametimesession();
      },
      deep: true,
    },
  },
  methods: {
    handleSalutation(e) {
      if (this.participant.salutation == "") {
        this.validation_message.salutation = ["This field is required."];
      } else {
        this.validation_message.salutation = [];
      }
    },
    handleEmail(e) {
      if (this.participant.email == "") {
        this.validation_message.email = ["This field is required."];
      } else if (!this.validateEmail(this.participant.email)) {
        this.validation_message.email = [
          "The email must be a valid email address.",
        ];
      } else {
        this.validation_message.email = [];
      }
    },
    handleLastName(e) {
      console.log(
        "this.participant.last_name.length",
        this.participant.last_name.length
      );
      if (this.participant.last_name == "") {
        this.validation_message.last_name = ["This field is required."];
      } else if (this.participant.last_name.length < 2) {
        this.validation_message.last_name = [
          "The last name must be at least 2 character long.",
        ];
      } else {
        this.validation_message.last_name = [];
      }
    },
    handleFirstName(e) {
      if (this.participant.first_name == "") {
        this.validation_message.first_name = ["This field is required."];
      } else if (this.participant.first_name.length < 2) {
        this.validation_message.first_name = [
          "The last name must be at least 2 character long.",
        ];
      } else {
        this.validation_message.first_name = [];
      }
    },
    handleJobLevel(e) {
      if (this.participant.job_level == "") {
        this.validation_message.job_level = ["This field is required."];
      } else {
        this.validation_message.job_level = [];
      }
    },
    handleOrgNature(e) {
      if (this.participant.organization_nature == "") {
        this.validation_message.organization_nature = [
          "This field is required.",
        ];
      } else {
        this.validation_message.organization_nature = [];
      }
    },
    handleOrgSize(e) {
      if (this.participant.organization_size == "") {
        this.validation_message.organization_size = ["This field is required."];
      } else {
        this.validation_message.organization_size = [];
      }
    },
    handleCountry(e) {
      if (this.participant.country == "") {
        this.validation_message.country = ["This field is required."];
      } else {
        this.validation_message.country = [];
      }
    },
    handleDpo(e) {
      if (this.participant.dpo == "") {
        this.validation_message.dpo = ["This field is required."];
      } else {
        this.validation_message.dpo = [];
      }
    },

    handleOrganisation(e) {
      if (this.participant.organization == "") {
        this.validation_message.organization = ["This field is required."];
      } else {
        this.validation_message.organization = [];
      }
    },
    handlePhone(e) { },
    handleJobTitle(e) {
      if (this.participant.job_title == "") {
        this.validation_message.job_title = ["This field is required."];
      } else {
        this.validation_message.job_title = [];
      }
    },

    handleConfirmEmail(e) {
      if (this.participant.email_confirmation == "") {
        this.validation_message.email_confirmation = [
          "This field is required.",
        ];
      } else if (
        this.participant.email_confirmation != this.participant.email
      ) {
        this.validation_message.email_confirmation = [
          "This email does not match.",
        ];
      } else {
        this.validation_message.email_confirmation = [];
      }
    },
    validateEmail(email) {
      // Email validation using regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

    customVal() {
      this.handleEmail({});
      this.handleLastName({});
      this.handleFirstName({});
      this.handleJobLevel({});
      this.handleOrganisation({});
      this.handleConfirmEmail({});
      this.handleJobTitle({});
      if (this.participant.job_level == "") {
        this.validation_message.job_level = ["This field is required."];
      } else {
        this.validation_message.job_level = [];
      }

      if (this.participant.salutation == "") {
        this.validation_message.salutation = ["This field is required."];
      } else {
        this.validation_message.salutation = [];
      }

      if (this.participant.organization_nature == "") {
        this.validation_message.organization_nature = [
          "This field is required.",
        ];
      } else {
        this.validation_message.organization_nature = [];
      }

      if (this.participant.organization_size == "") {
        this.validation_message.organization_size = ["This field is required."];
      } else {
        this.validation_message.organization_size = [];
      }

      if (this.participant.country == "") {
        this.validation_message.country = ["This field is required."];
      } else {
        this.validation_message.country = [];
      }

      if (this.participant.dpo == "") {
        this.validation_message.dpo = ["This field is required."];
      } else {
        this.validation_message.dpo = [];
      }
    },
    store() {

      this.selectanyone = "";
      this.loading = true;
      let errorFlag = false;
      this.resetValidation();
      this.resetcapacitymessage();

      if (!this.terms_checkbox) {
        this.showError = true;
        this.loading = false;
        errorFlag = true;
        // $("#failedModal").modal("show");
      } else {
        this.showError = false;
      }

      if (
        this.participant.pdp_week == 0 &&
        this.participant.pets_101 == 0 &&
        this.participant.decoding_generative_ai == 0 &&
        this.participant.navigating_cross_border_data_flows == 0 &&
        this.participant.workshop_on_ai_governance == 0
      ) {
        this.selectanyone = "Please select at least one session.";
        console.log(this.participant);
        this.loading = false;
        // $("#failedModal").modal("show");
        if (!errorFlag) {
          document.getElementById("session_all").scrollIntoView({
            behavior: "smooth",
          });
        }
        errorFlag = true;
      }

      if (errorFlag) {
        this.customVal();
        this.loading = false;

        return;
      }

      // console.table(this.participant);
      // return;
      Api.post("participant", this.participant).then((res) => {
        if (res.data.validation_error) {
          this.validation_message = res.data.validation_message;
          this.loading = false;
          // console.log(
          //   res.data.validation_message,

          // );
          if (
            res.data.validation_message.decoding_generative_ai ||
            res.data.validation_message.workshop_on_ai_governance ||
            res.data.validation_message.navigating_cross_border_data_flows ||
            res.data.validation_message.pets_101 ||
            res.data.validation_message.pdp_week
          ) {
            document.getElementById("session_all").scrollIntoView({
              behavior: "smooth",
            });
          } else if (Object.keys(res.data.validation_message).length > 4) {
            console.log(res.data.validation_message);
            $(window).scrollTop(0);
          }

          else {
            $(window).scrollTop(0);
          }

          // $("#failedModal").modal("show");

          return;
        }

        if (res.data.capacity_error) {
          this.capacity_message = res.data.capacity_message;
          this.loading = false;
          document.getElementById("session_all").scrollIntoView({
            behavior: "smooth",
          });
          return;
        }
        if (res.data.stripe_url) {
          location.href = res.data.stripe_url;
          return;
        }
        this.successModal(res.data.waitlist_only);
        this.resetValidation();
        this.resetcapacitymessage();
      });
    },
    successModal(data) {
      this.terms_checkbox = false;
      this.participant = {
        frst_name: "",
        last_name: "",
        email: "",
        email_confirmation: "",
        salutation: "",
        industry: "",
        occupation: "",
        organization: "",
        designation: "",
        organization_sme: "",
        dpo: "",
        pdp_seminar_session: 0,
        th_data_soverignty_session: 0,
        virtual_roadahead_session: 0,

        fr_dpo_session: 0,
        fr_navigating_breach_session: 0,

        wed_data_protection_obligation_session: 0,
        wed_navigating_data_breach_session: 0,

        industry_others: "",
        phone: "",
        reg_type: "virtual",
      };
      this.loading = false;
      this.selectanyone = "";
      this.selected_session_count = 0;
      if (data) $("#waitListModal").modal("show");
      else $("#successModal").modal("show");
    },
    resetValidation() {
      this.validation_message = {
        frst_name: "",
        last_name: "",
        email: "",
        email_confirmation: "",
        salutation: "",
        industry: "",
        occupation: "",
        organization: "",
        designation: "",
        organization_sme: "",
        dpo: "",
        industry_others: "",
        phone: "",
      };
    },
    resetcapacitymessage() {
      this.capacity_message = {
        pdp_limit: "",
        th_data_soverignty_session: "",
        virtual_roadahead_session: "",
        fr_dpo_session: "",
        fr_navigating_breach_session: "",
      };
    },
    sametimesession() {
      if (
        !this.participant.fr_dpo_session &&
        !this.participant.fr_navigating_breach_session
      ) {
        this.disable = "";
      }

      if (this.participant.fr_dpo_session) {
        this.disable = "fr_navigating_breach_session";
      }

      if (this.participant.fr_navigating_breach_session) {
        this.disable = "fr_dpo_session";
      }
    },
    // CheckSessionCount() {
    //   this.selected_session_count = 0;

    //   if (this.participant.pdp_seminar_session) {
    //     this.selected_session_count++;
    //   }
    //   if (this.participant.th_data_soverignty_session) {
    //     this.selected_session_count++;
    //   }
    //   if (this.participant.virtual_roadahead_session) {
    //     this.selected_session_count++;
    //   }
    //   if (this.participant.fr_dpo_session) {
    //     this.selected_session_count++;
    //   }
    //   if (this.participant.fr_navigating_breach_session) {
    //     this.selected_session_count++;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.register {
  & label {
    font-weight: 500;
  }

  & .sessions {
    & .session {
      & .card {
        border-radius: 50px !important;
      }

      label {
        font-weight: 700;
      }
    }
  }
}
</style>
